<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Aset Belum Bersertifikat </strong><small>Detail Aset</small>            
            <a href="/#/asetunregis/list" class="float-right btn btn-secondary form-control-sm">Kembali</a>
            <a :href="linkedit" class="float-right btn btn-success form-control-sm" style='margin-right:10px'>Edita Data</a>
            
          </CCardHeader>
          <CCardBody>
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td style="width:20%">KODE SKPD</td>
                  <td style="width:30%">: <strong>{{kode_skpd}}</strong></td>
                  <td style="width:20%">NAMA SKPD</td>
                  <td style="width:30%">: <strong>{{nama_skpd}}</strong></td>
                </tr>
                
                <tr>
                  <td>KOTA/KABUPATEN</td>
                  <td>: <strong>{{kabupaten_kota}}</strong></td>
                  <td>KODE LOKASI</td>
                  <td>: <strong>{{kode_lokasi}}</strong></td>
                </tr>
                <tr>
                  <td>NAMA OPD</td>
                  <td>: <strong>{{nama_instansi}}</strong></td>
                  <td>LETAK/ALAMAT</td>
                  <td>: <strong>{{letak_lokasi}}</strong></td>
                </tr>
                <tr>
                  <td>KODE BARANG</td>
                  <td>: <strong>{{kode_barang}}</strong></td>
                  <td>NAMA BARANG</td>
                  <td>: <strong>{{nama_barang}}</strong></td>
                </tr>
                <tr>
                  <td>NO HAK</td>
                  <td>: <strong>{{no_hak}}</strong></td>
                  <td>TIPE HAK</td>
                  <td>: <strong>{{jenis_hak}}</strong></td>
                </tr>
                <tr>
                  <td>TANGGAL PEROLEHAN</td>
                  <td>: <strong>{{tgl_perolehan}}</strong></td>
                  <td>HARGA PEROLEHAN</td>
                  <td>: <strong>{{nilai_perolehan}}</strong></td>
                </tr>
                <tr>
                  <td>LUAS (m2)</td>
                  <td>: <strong>{{luas_tertulis}}</strong></td>
                  <td>TANGGAL SERTIFIKAT</td>
                  <td>: <strong>{{tgl_sertifikat}}</strong></td>
                </tr>
              </tbody>
            </table>

            <table class="table table-striped">
              <tr>
                <td>                  
                  <div class="form-group" style="height: 200px;width: 100%;" >
                    <img :src="foto_lokasi" class="img-fluid" style="height: 100%;width: 100%;" />
                    <label><b>Foto Lokasi </b> <a href='' target='_blank'>[Download]</a></label> 
                  </div>
                </td>
                <td>
                  <div class="form-group" style="height: 200px;width: 100%;" >
                    <img :src="sertifikat" class="img-fluid" style="height: 100%;width: 100%;" />
                    <label><b>Sertifikat </b> <a href='' target='_blank'>[Download]</a></label>
                  </div>
                </td>
                <td>
                  <div class="form-group" style="height: 200px;width: 100%;">
                    <img :src="buku_tanah" class="img-fluid" style="height: 100%;width: 100%;" />
                    <label><b>Buku Tanah </b> <a href='' target='_blank'>[Download]</a></label> 
                  </div>
                </td>
              </tr><br/>
              <tr>
                <td>
                  <div class="form-group" style="height: 200px;width: 100%;" >
                    <img :src="alas_hak" class="img-fluid" style="height: 100%;width: 100%;" />
                    <label><b>Alas Hak</b> <a href='' target='_blank'>[Download]</a></label> 
                  </div>
                </td>
                <td>
                  <!-- <div class="form-group" style="height: 200px;width: 100%;" >
                    <img :src="bangunan" class="img-fluid" style="height: 100%;width: 100%;" />
                  </div> -->
                </td>
                <td></td>
              </tr>
            </table>

            <div style="height: 400px;width: 100%;" >
              <l-map ref="mapElement" :zoom="zoom" :center="center" style='height: 100%;width: 100%;'>
                <l-tile-layer :url="url"></l-tile-layer>
                <l-geo-json ref="track" :geojson="geojsonFile"  ></l-geo-json>
                <!-- <l-polygon :lat-lngs="latlngs" color="red"></l-polygon> -->
              </l-map>
            </div>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      :show.sync="myerror"
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>
<style scoped>
  .leaflet-control-attribution a{
    display: none !important
  }
</style>
<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'
import { LMap, LTileLayer, LPolygon, LMarker, LGeoJson } from 'vue2-leaflet';

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolygon,
    LGeoJson
  },
  data () {
    return {
      linkedit :"/#/asetunregis/edit/"+this.$route.params.id_aset_bersertifikat,
      placeholder :"",
      kode_skpd : "",
      nama_skpd : "",
      kabupaten_kota : "",
      kode_lokasi : "",
      latlngs: [[109.290056324599,-0.0124423568214555],[109.29122650719,-0.0160182734285108],[109.291621915063,-0.0158496126787141],[109.29045346265801,-0.0122778678589834],[109.290056324599,-0.0124423568214555]],
      nama_instansi : "",
      letak_lokasi : "",
      kode_barang : "",
      nama_barang : "",
      tgl_perolehan : "",
      nilai_perolehan : "",
      tgl_sertifikat : "",
      luas_tertulis : "",
      no_hak : "",
      jenis_hak : "",
      file : "",
      filename : "",
      myerror : false,
      placeholder : "Pilih File ",
      selected: [], // Must be an array reference!
      ruangans: [],
      lemaris: [],
      koloms: [],
      bariss: [],
      show: true,
      url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">BPN Kantah Kota Pontianak</a>',
      zoom: 14,
      center: [-0.0353948,109.2615099],
      centerGeojson: [],
      coordinates:[],
      details:{},
      geojsonFile : {}
    }
  },
  mounted(){
    this.showAsetunregis();
  // this.loadData();
    // this.details=JSON.parse(this.$store.state.properties);
    // var koor = JSON.parse(this.$store.state.coordinates);
    // var koor2=JSON.stringify(koor.substring( 3, koor.length - 3 ));
    // this.coordinates = koor2;
    // this.center=this.findCenter(koor2);
    console.log("koor "+(this.$store.state.coordinates));
    this.geojsonFile = {
        "type":"FeatureCollection", 
        "features": 
          [
            {
            "type":"Feature",
            "geometry":{
                "type":"Polygon",
                "coordinates":JSON.parse(this.$store.state.coordinates)
            },
            "properties":null
            }
          ]
    };

    var newKoor = JSON.parse(this.$store.state.coordinates);
    // alert(newKoor[0][0]);
    var cntr = [newKoor[0][0][0],newKoor[0][0][1]];
    this.centerGeojson.push(newKoor[0][0][1]);
    this.centerGeojson.push(newKoor[0][0][0]);
    // alert(cntr);

    this.$refs.mapElement.mapObject.panTo(this.centerGeojson);
   
    // alert(JSON.stringify(this.geojsonFile));
    //After building your geoJson layers, just call this:
  // this.$nextTick().then(() => {
  //     var group = new featureGroup();

  //     this.$refs.mapElement.mapObject.eachLayer(function(layer) {
  //         if (layer.feature != undefined)
  //             group.addLayer(layer);
  //     });

  //     this.$refs.mapElement.mapObject.fitBounds(group.getBounds(), { padding: [20, 20] });
  // });
    // var mymap = L.map(this.$refs['mapElement']).setView([51.505, -0.09], 13);
    // document.getElementsByClassName('leaflet-control-attribution').style.display = 'none';
    // map.attributionControl.setPrefix(false);
    // L.map('map', {attributionControl: false})
  },

  methods: {
    // toggle(id, koor, prop) {
      
    //   this.$store.commit('setProp', prop);
    //   this.$store.commit('setCoord', koor);
    //   this.$router.push("/asetwilayah/edit/"+id);
    // },
    doSomethingOnReady() {
      this.track = this.$refs.track.mapObject;
      this.map.fitBounds(this.track.getBounds(), {maxZoom: 13});
      console.log("Bounds:", this.track.getBounds());
    },
    findCenter:function(markers) {
      let lat = 0;
      let lng = 0;
      
      for(let i = 0; i < markers.length; ++i) {
          lat += markers[i].lat;
          lng += markers[i].lng;
      }

      lat /= markers.length;
      lng /= markers.length;

      return [lat,lng]
  },
    loadData: function(event){

      axios.get("http://139.180.219.157/earsip/api/public/raw_ruangan/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
        .then((response) => {
              this.ruangans=response.data;
        });
      axios.get("http://139.180.219.157/earsip/api/public/raw_lemari/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
        .then((response) => {
              this.lemaris=response.data;
        });
      axios.get("http://139.180.219.157/earsip/api/public/raw_kolom/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
        .then((response) => {
              this.koloms=response.data;
        });
      axios.get("http://139.180.219.157/earsip/api/public/raw_baris/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
        .then((response) => {
              this.bariss=response.data;
        });

    },
    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.filename = fileData.name;
      this.file = fileData;
      this.placeholder = fileData.name;
      // alert(fileData.name);
    },
    
    showAsetunregis: function(event){
    // Simple POST request with a JSON body using axios
    const asetunregis = { 
                          kode_skpd: this.kode_skpd, 
                          nama_skpd: this.nama_skpd,
                          kabupaten_kota: this.kabupaten_kota,
                          kode_lokasi: this.kode_lokasi,
                          nama_instansi: this.nama_instansi,
                          letak_lokasi: this.letak_lokasi,
                          kode_barang: this.kode_barang,
                          nama_barang: this.nama_barang,
                          no_hak: this.no_hak,
                          jenis_hak: this.jenis_hak,
                          tgl_perolehan: this.tgl_perolehan,
                          nilai_perolehan: this.nilai_perolehan,
                          luas_tertulis: this.luas_tertulis,
                          tgl_sertifikat: this.tgl_sertifikat,
                          dikuasai: this.dikuasai,
                          
                          nama_pendaftar: this.nama_pendaftar,
                          no_hp: this.no_hp,
                          jumlah_bidang: this.jumlah_bidang,
                          tgl_pendaftaran: this.tgl_pendaftaran,
                          foto_lokasi: this.foto_lokasi,
                          plang_tanah: this.plang_tanah,
                          pagar: this.pagar,
                          plang_tanah: this.plang_tanah
                        };
    // alert(JSON.stringify(pekerjaan));
    // alert(this.$route.params.id_User);

    let username =window.btoa(this.$route.params.id_user);
    console.log(username);

    axios.get(process.env.VUE_APP_BASE_URL+"detailasetbelumbersertifikat/"+this.$route.params.id_aset_bersertifikat)
      .then((response) => {
                  
              this.kode_skpd=response.data.kode_skpd;
              this.nama_skpd=response.data.nama_skpd;
              this.kabupaten_kota=response.data.kabupaten_kota;
              this.kode_lokasi=response.data.kode_lokasi;
              this.nama_instansi=response.data.nama_instansi;
              this.letak_lokasi=response.data.letak_lokasi;
              this.kode_barang=response.data.kode_barang;
              this.nama_barang=response.data.nama_barang;
              this.no_hak=response.data.no_hak;
              this.jenis_hak=response.data.jenis_hak;
              this.tgl_perolehan=response.data.tgl_perolehan;
              this.nilai_perolehan=response.data.nilai_perolehan;  
              this.luas_tertulis=response.data.luas_tertulis;       
              this.tgl_sertifikat=response.data.tgl_sertifikat; 

              // this.no_hp=response.data.no_hp;       
              // this.tgl_pendaftaran=response.data.tgl_pendaftaran;       
              // this.jumlah_bidang=response.data.jumlah_bidang;       
              // this.dikuasai=response.data.dikuasai;       
              this.foto_lokasi=response.data.foto_lokasi;       
              // this.plang_tanah=response.data.plang_tanah;       
              // this.patok_batas=response.data.patok_batas;       
              // this.pagar=response.data.pagar;       
            var newKoor = jsonpeta.geometry.coordinates;
    // alert(newKoor[0][0]);
    var cntr = [newKoor[0][0][0],newKoor[0][0][1]];
    this.centerGeojson.push(newKoor[0][0][0][0].substring(-1));
    // this.centerGeojson.push(newKoor[0][0][0]);
    // alert(this.centerGeojson);

    this.$refs.mapElement.mapObject.panTo(this.centerGeojson);           
      })
    }
  }
}
</script>